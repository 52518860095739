<template>
  <div class="problemReport pt-2 px-4">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <div class="row">
        <div
          v-if="installationIdNotPredefined"
          class="col-12 col-md-6 mb-4"
        >
          <label>{{ $t('installation') }}</label>
          <multiselect
            ref="vms"
            v-model="problemReport.installation"
            :class="['m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InstallationId') } ]"
            :options="installations"
            :taggable="false"
            :show-labels="true"
            :searchable="true"
            :close-on-select="true"
            :hide-selected="true"
            :multiple="true"
            deselect-label="Click to deselect"
            select-label="Click to select"
            placeholder="select installation"
            label="name"
            track-by="id"
          >
            <template
              slot="option"
              slot-scope="{option}"
            >
              <span>{{ option.name }}</span>
            </template>
            <template
              slot="tag"
              slot-scope="{option}"
            >
              <span
                class="m-badge m-badge--primary m-badge--wide"
                @click="removeFromSelection(option)"
              >{{ option.name }} &times;</span>
            </template>
          </multiselect>
          <span
            v-show="errors.has('InstallationId')"
            class="badge badge-danger"
          >{{ errors.first('InstallationId') }}</span>
        </div>
        <div
          v-if="installationIsSelected"
          class="col-12 col-md-6"
        >
          <label>{{ $t('severity') }}</label>
          <hr class="m-0 mb-2">
          <div class="m-radio-inline">
            <label class="m-radio m-radio--state-danger">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="problemReportRadioBoxes"
                value="5"
                class="pl-2"
              >{{ $t('disaster') }}<span />
            </label>
            <label class="m-radio m-radio--state-warning">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="problemReportRadioBoxes"
                value="4"
                class="pl-2"
              >{{ $t('high') }}
              <span />
            </label>
            <label class="m-radio m-radio--state-focus">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="problemReportRadioBoxes"
                value="3"
                class="pl-2"
              >{{ $t('checkAgainTomorrow') }}
              <span />
            </label>
            <label class="m-radio m-radio--state-info">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="problemReportRadioBoxes"
                value="2"
                class="pl-2"
              >{{ $t('information') }}
              <span />
            </label>
          </div>
        </div>
      </div>

      <template v-if="readyToFillForm">
        <div class="row mt-4">
          <div class="col-12">
            <label>{{ $t('description') }}</label>
            <textarea
              v-model="problemReport.text"
              :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Message') }]"
              name="Message"
              rows="3"
              placeholder="Please describe the problem"
              @keydown.ctrl.enter="sendComment()"
            />
            <span
              v-show="errors.has('Message')"
              class="badge badge-danger"
            >{{ errors.first('Message') }}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <button
              class="btn btn-sm btn-primary float-right"
              @click.prevent="sendComment()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="chart-area"
              />
              <span>
                {{ $t('report') }}
              </span>
            </button>
            <div class="clearfix" />
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import problemReportAdapter from '@/adapter/problemReportAdapter.js';

import { errorMixin } from '@/mixins/errorMixin.js';
import { setTimeout } from 'timers';

export default {
  name: "ProblemReport",
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    problemReportAdapter,
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      installations: null,
      problemReport: {
        product: 0,
        installation: "",
        severity: 5,
        text: "",
        user: ""
      }
    }
  },
  computed: {
    readyToFillForm: function () {
      if (this.installations || this.installationId) return true;
      return false;
    },
    installationIdNotPredefined: function () {
      if (!this.installationId && this.installations) return true;
      return false;
    },
    installationIsSelected: function () {
      if (this.installations || this.installationId) return true;
      return false;
    }
  },
  created () {
    this.getInstallations();
  },
  methods: {
    removeFromSelection (option) {
      this.problemReport.installation.splice(this.problemReport.installation.indexOf(option), 1);
    },
    getInstallations: function () {
      // make sure the instance-scope can be accessed
      if (!this.installationId) {
        // call to api using axios
        this.axios.get('/Installation/GetAllActive')
          .then((response) => {
            // assign the installations to the vue instance
            this.installations = response.data;
            this.loading = false;
          });
      } else {
        this.problemReport.installation = [{id: this.installationId}];
        this.loading = false;
      }
      this.focusOnMulti();
    },
    focusOnMulti () {
      if (typeof this.$refs.vms === 'undefined') {
        setTimeout(() => {
          this.focusOnMulti();
        }, 250);
      } else {
        this.$refs.vms.$el.focus();
      }
    },
    sendComment () {
      if (typeof this.problemReport.installation != 'undefined' && this.problemReport.installation.length > 0) {
        this.loading = true;
        // Loop through the installations and send one message each time
        for (let i = 0; i < this.problemReport.installation.length; i++) {
          let id = this.problemReport.installation[i].id;
          this.processComment(id);
        }
      } else {
        this.processComment();
      }
    },
    processComment (installationId) {
      let zabbixRequest = this.sendProblemReport(installationId, this.problemReport.text, this.problemReport.severity, 'Problem');

      zabbixRequest.then(() => {
        this.$snotify.success("The problem report was successfully sent.");
        this.problemReport.severity = 5;
        this.problemReport.text = "";
        if (!this.installationId) {
            this.problemReport.installation = null;
        }
        this.$eventBus.$emit('refreshHistory');
        this.$eventBus.$emit('refreshProblems');
        this.error_clear();
        this.loading = false;
      })
      .catch((error) => {
          this.error_clear();
        this.error_validate(error);
        this.loading = false;
      });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
label:focus-within {
  outline: 1px solid lightblue;
}
</style>
