export default {
    methods: {
        sendProblemReport (installationId, message, severity, type) {

            let report = {
                'InstallationId': installationId,
                'Message': message,
                'Type': type,
                'Severity': severity
            };

            return this.axios.post('/Audit/ReportProblem', report);
        },
        createNote (installationId, message, severity, usersToNotify) {

            let report = {
                'InstallationId': installationId,
                'NoteMessage': message,
                'Severity': severity,
                'UsersToNotify': usersToNotify
            };

            return this.axios.post('/Audit/CreateNote', report);
        }
    }
}